import React from 'react';
import '../styles/global.scss';
import Layout from '../components/layout';
import CCLLeadspace from '../components/cclLeadspace';
import HealthcareStats from './partials/healthcareStats';
import CardSlider from '../components/cardSlider';
import JourneyForm from '../components/journeyForm';
import CCLButton from '../components/cclButton';
import CCLExplore from '../components/cclExplore';
import '../styles/global.scss';
import LeadImage from '../images/healthcare-leadspace.jpg';
import SEO from '../components/seo';
import Story1 from '../images/stories/healthcare1.jpg';

let cards = [
    {
        header: 'Explore our stories of impact & our latest thinking',
        subheader: '',
        cards: [
            {
                title: '3 Keys to U.S. Health System Success in the 2020s',
                link: 'https://www.ccl.org/articles/white-papers/us-health-system-success/',
                label: 'Download White Paper'
            },
            {
                title: 'A Prescription to Develop New Leaders in a Global Healthcare Organization',
                link: 'https://www.ccl.org/articles/client-successes/a-prescription-to-develop-new-leaders-in-a-global-healthcare-organization/',
                label: 'View Client Success'
            },
            {
                title: 'Are You Facing a Problem? Or a Polarity?',
                link: 'https://www.ccl.org/articles/leading-effectively-articles/are-you-facing-a-problem-or-a-polarity/',
                label: 'Read Article'
            },
            {
                title: 'Making Coaching a Positive Development Opportunity',
                link: 'https://www.ccl.org/articles/client-successes/making-coaching-a-positive-development-opportunity/',
                label: 'View Client Success'
            },
            {
                title: 'Disruptive Trends Report: Talent Reimagined 2020',
                link: 'https://www.ccl.org/articles/research-reports/talent-reimagined-disruptive-trends-2020/',
                label: 'Read Trends Report'
            },
            {
                title: 'How to Manage Paradox',
                link: 'https://www.ccl.org/articles/leading-effectively-articles/manage-paradox-for-better-performance/',
                label: 'Read Article'
            },
        ]
    }
];

const Healthcare = () => (
    <Layout>
        <SEO
            title="Healthcare Leadership | CCL"
            description="In an ever changing world, one constant fact remains: great leadership can turn challenge into opportunity. See how CCL has helped healthcare organizations move forward."
            meta={{
                imgAlt: 'Healthcare leadership executives looking to the future. '
            }}
            noFollowIndexed
        />
        <CCLLeadspace image={LeadImage} copy="RESILIENT.  CONFIDENT.  READY FOR TOMORROW." backgroundAlginRight alt="Healthcare leadership executives looking to the future" />
        <div className="black-text-box">
            <h2>Change is the only constant. And change is more rapid and complex than ever before. How can healthcare organizations navigate current disruptions while delivering value and providing excellent service as we move into the next normal in the future of healthcare? Resilience and confidence are foundational to understanding what is required to lead at your best in this new reality.</h2>
            <h2>The Center for Creative Leadership has the leadership development tools to guide you through this change – and the uncertain future that lies ahead.</h2>
        </div>
        <HealthcareStats />
        <div className="stories">
            <span>
                <CCLExplore
                    className='story'
                    label='FEATURED WEBINAR'
                    description={<>Leadership in the Future of Healthcare: Befriending Polarity & Paradox<br /><br />Date: Tuesday, July 21st<br />Time: 1:00 PM – 2:00 PM EDT</>}
                    image={Story1}
                    link='https://cclwebinars.webvent.tv/webinar/3839'
                    cta='register now'
                    btnClass='primary'
                    isFinancialCard />
            </span>
            <div className="description">
                <div className="wrapper">
                    <div>Attend this webinar and learn:</div>
                    <ul>
                        <li>How changes brought on by COVID-19 will affect the future of healthcare and leadership in this critical space.</li>
                        <li>The 6 leadership paradoxes related to leading effectively in the healthcare ecosystem’s "next normal."</li>
                        <li>Next steps for assessing your leadership response in a paradoxical world.</li>
                    </ul>
                </div>
            </div>
        </div>

        <div className="testimonial-section">
            <div className="white-bg-quote-text">
                <h2 className="text-wrapper">
                    "Through our partnership with CCL, we have created a critical mass of leaders who are connected, collaborative and committed to our mission. We are working in more system-focused ways and leveraging our strengths in ways we never anticipated."
        </h2>
            </div>
            <div className="shape-divider">
                <div className="shape-left"></div>
                <div className="shape-right"></div>
            </div>
            <div className="name">—Jon Abeles, SVP, Talent Management & Diversity, Mercy Health</div>
        </div>
        <CardSlider {...cards} />
        <JourneyForm formName="lns-healthcare" interestCode="FORMVERTCONTACTHCLNS" description="The world has changed. Your industry is transforming. Your leaders are stepping up. Let’s keep the momentum going. Contact CCL to talk more about your needs." btnTxt="REQUEST CONSULTATION" />
    </Layout>
);

export default Healthcare;
